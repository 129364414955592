import React from 'react';

function Footer() {
  return (
    <>
      <div className="container no-margin">
        <div className="row">
          <div className="col-md-12">
            <img src="images/timeline_footer.png" className="img-responsive center-block" alt="" />
          </div>
        </div>
      </div>

      <footer>
        <div class="container no-margin">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex align-items-center footer-mobile">
                <img src="images/logo-vgp-footer.png" class="img-responsive logo-footer" alt="" />
                <br class="show-mobile" />
                <span>© 2020 Videogames Party. All right reserved.
                  <br class="show-mobile" />
                  <br class="show-mobile" />
                  <a href="https://www.iubenda.com/privacy-policy/65151050" target="_blank">Privacy Policy</a>
                  <br class="show-mobile" />
                  <br class="show-mobile" />
                  <a href="https://www.iubenda.com/privacy-policy/65151050/cookie-policy" target="_blank">Cookie Policy</a>
                </span>
                <br class="show-mobile" />
                <br class="show-mobile" />
                <span class="footer-powered">
                  <span>powered by</span>
                  <img src="images/logo-intel.png" alt="" style={{width: '70px'}} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
