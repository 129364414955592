import React from 'react';

function Game({ game }) {
  return (
    <div className="item">
      <img src={`../images/${game.image}`} className="img-responsive center-block" alt="" />
    </div>
  )
}

export default Game
