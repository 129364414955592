import React from 'react';

function Spinner() {

  return (
    <div className="container-spinner">
      <div className="row">
        <div className="col-md-12" style={{"textAlign": "center"}}>
          <img src="images/logo-pc-gaming.png" className="center-block img-responsive center-logo" alt="" />
          <h1> Buon viaggio... </h1>
        </div>
      </div>
    </div>
  )
}

export default Spinner
