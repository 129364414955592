import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

// import struttura from './struttura.json';

import Home from './pages/Home';
import Decadi from './pages/Decadi';
import Decade2020 from './pages/Decade2020';
import Decade from './pages/Decade';
import Spinner from './pages/Spinner';

function App() {
  const [struttura, setStruttura] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch('/struttura.json')
      .then(response => response.json())
      .then(data => setStruttura(data))
      .then(setLoading(false));
  }, [])

  if(struttura === null) {
    return <Spinner />;
  }

  return(
    <>
      { loading ? <Spinner /> : <Router>
        <Switch>
          <Route path="/decade/:year">
            <Decade />
          </Route>

          <Route path="/decadi">
            <Decadi struttura={struttura} />
          </Route>

          <Route path="/">
            <Home struttura={struttura} />
          </Route>

        </Switch>
      </Router>
      }
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
