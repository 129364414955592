import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";

import DecadeSlider from '../components/DecadeSlider';
import Spinner from './Spinner';

function Decadi({ struttura }) {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${window.location.origin}/js/slider.js`;
    console.log( `${window.location.origin}/js/slider.js`);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return ( 
      <>
        <div className="container-fluid menu-top ">
          <div className="container no-margin">

            <div className="d-flex align-items-center justify-flex-end menu-mobile" style={{margin: '25px 0'}}>
              <div className="menu-top--sx">
                <Link to="/" >
                  <img src="../images/logo-sticky.png" className="img-responsive logo-sticky" alt="" />
                </Link>
              </div>
              <div className="menu-top--dx">
                <p className="pre-logo-intel show-full">powered by</p><img src="images/logo-intel.png" className="logo-intel" alt="" /><div className="divider show-full"></div><img src="images/logo-vgp-footer.png" className="logo-vgp-intro show-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="slider" className="" >
          <div className="owl-carousel owl-theme">
            {/* start decadi slider here */}

            { struttura != null && Object.keys(struttura.years).map( year => {
              return (<DecadeSlider key={`slider_${year}`} year={year} text={struttura.years[year]['text']} />)
            } ) }

            {/* end decadi slider here */}
          </div>
        </div>

        <div className="container-fluid menu-interno d-flex align-items-center justify-content-center">
          <div id="menu">
            <span className="divider"></span>

            { struttura != null && Object.keys(struttura.years).map( year => {
              return (
                <React.Fragment key={`frag_${year}`}>
                  <span><a href="#" id={year} className={`menu-interno__link ${year === '1980' ? 'active' : ''}`}>{year}</a></span>
                  <span className="divider"></span>
                </React.Fragment>
              )})}
          </div>
        </div>

        <footer>
          <div className="container no-margin">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center footer-mobile">
                  <img src="images/logo-vgp-footer.png" className="img-responsive logo-footer" alt="" />
                  <br className="show-mobile" />
                  <span>© 2020 Videogames Party. All right reserved.<br className="show-mobile" /><br className="show-mobile" /> <a href="/">Privacy Policy</a><br className="show-mobile" /><br className="show-mobile" /> <a href="/">Cookie Policy</a></span>
                  <br className="show-mobile" />
                  <br className="show-mobile" />
                  <span className="footer-powered"><span>powered by</span> <img src="images/logo-intel.png" alt="" style={{ width: '80px' }} /></span>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
  );
}

export default Decadi;
