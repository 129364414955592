import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Link,
  useParams
} from "react-router-dom";

import Year from '../components/Year';
import Spinner from './Spinner';
import YearSlider from '../components/YearSlider'

import year_1980 from '../json/1980.json';
import year_1990 from '../json/1990.json';
import year_2000 from '../json/2000.json';
import year_2010 from '../json/2010.json';
import year_2020 from '../json/2020.json';

function Decade(props) {
  let { year } = useParams();

  const [loading, setLoading] = useState(false);
  // const [struttura, setStruttura] = useState({});
  // const [decade, setDecade] = useState(null);
  // const [years, setYears] = useState({});

  // useEffect(async () => {
  //   const result = await axios(
  //     `${window.location.origin}/json/${year}.json`
  //   );
  //
  //   setDecade(result.data);
  //   setLoading(false);
  // }, []);

  // useEffect(() => {
  //   fetch(`${window.location.origin}/json/${year}.json`)
  //     .then(response => response.json())
  //     .then(data => setDecade(data))
  //     .then(setLoading(false));
  // }, [year])

  let decade = year_1980;

  switch(year) {
    case '1980':
      decade = year_1980;
      break;
    case '1990':
      decade = year_1990;
      break;
    case '2000':
      decade = year_2000;
      break;
    case '2010':
      decade = year_2010;
      break;
    case '2020':
      decade = year_2020;
      break;
    default:
      decade = year_1980;
  }

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${window.location.origin}/js/decade.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  });

  if(decade === null) {
    return <Spinner />;
  }

  return (
    <>
      { loading ? <Spinner /> : <>

        <div className="menu-top">
          <div className="container-fluid menu-top ">
            <div className="container no-margin">
              <div className="d-flex align-items-center justify-flex-end menu-mobile" style={{margin: '25px 0'}}>
                <div className="menu-top--sx">
                  {/* <Link to="/decadi" className="btn-decadi">◀&nbsp;&nbsp;Torna alle decadi</Link> */}
                  <Link to="/" >
                    <img src="../images/logo-sticky.png" className="img-responsive logo-sticky" alt="" />
                  </Link>
                </div>
                <div className="menu-top--dx">
                  <p className="pre-logo-intel show-full">powered by</p>
                  <img src="../images/logo-intel.png" className="logo-intel" alt="" />
                  <div className="divider show-full"></div>
                  <img src="../images/logo-vgp-footer.png" className="logo-vgp-intro show-full" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="layer"></div>
        </div>

        <div className="intro-home intro-home__interno">
          <div className="claim-home">
            <img src={`../images/${year}.png`} className="img-responsive center-block logo-banner-interno" alt="" />
          </div>
          <div className="cover-video">
            <img src={`../images/slide-${year}.jpg`} alt="" />
          </div>
          <div className="layer"></div>
        </div>
            
        <div className="container-fluid menu-interno d-flex align-items-center justify-content-center">
          <div id="menu">
            <span className="divider"></span>

            { decade.years && decade["years"].map(this_year => { return(
              <React.Fragment key={`frag_${year}`}>
                <span>
                  <a href={`#container_${this_year.year}`} id={`link_${this_year.year}`} className="menu-interno__link page-scroll">{`'${this_year.year.substring(2)}`}</a>
                </span>
                <span className="divider"></span>
              </React.Fragment>
            )})}
          </div>
        </div>

        { decade.years && decade["years"].map( this_year => { return <Year key={`this_year_${this_year['year']}`} {...this_year} /> } )}

        <YearSlider year={decade.years[0].year} />
      </>
      }
    </>
  )
}

export default Decade
