import React from 'react';
import {
  Link
} from "react-router-dom";

function DecadeSlider(props) {
  const year = props['year'];
  const text = props['text'];

  const decade = ["2000", "2010", "2020"].includes(year) ? year : "'" + (year + "").substring(2, 4);

  return (
    <div className="item" id={`_${year}`}>
      <img src={`images/slide-${year}.jpg`} className="show-full" alt="" />
      <img src={`images/slide-${year}_mobile.jpg`} className="show-mobile" alt="" />
      <div className="text-slide">
        <div className="col-md-6 col-lg-4 decade-description">
          <img src={`images/${year}.png`} alt="" />
          <br/>
          <span className="show-full">
            <p>{text}</p>
          </span>
          <br/>
          <Link to={`/decade/${year}`} className="btn btn--dark">Esplora gli anni {decade}</Link>
        </div>
      </div>
      <div className="layer"></div>
    </div>
  )
}

export default DecadeSlider
