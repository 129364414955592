import React from 'react';
import Game from './Game';

function Games({ games }) {

  return (
        <div className="row"> <div className="col-md-12">
            <div className="owl-carousel owl-theme giochi">
              { games.map( game => {return (
                <Game key={game.image} game={game} />
              )} )}
            </div>
          </div>
        </div>
  )
}

export default Games
