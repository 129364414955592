import React from 'react';
import {
  Link
} from "react-router-dom";
import './trends.css'

function YearSlider({year}) {
  const decade = Math.floor(parseInt(year) / 10) * 10;

  const prev = decade > 1980 ? decade - 10 : null; 
  const next = decade < 2020 ? decade + 10 : null;

  return (
    <div className="row">
      <div className="col-md-12">
        { prev &&
        <a href={`/decade/${prev}`} className="btn btn--dark pull-left navigazione-anni">
          { prev } <img src={`/images/icon_${prev}.png`} className="navigazione-anni__icona" />
        </a> }
        { next &&
        <a href={`/decade/${next}`} className="btn btn--dark pull-right navigazione-anni">
          { next } <img src={`/images/icon_${next}.png`} className="navigazione-anni__icona" />
        </a> }
      </div>
    </div>
  )
}

export default YearSlider
