import React from 'react';
import './trends.css'

function Trends({text, trends}) {

  return (
    <div className="row">
      <div className="col-md-8 col-sm-12 col-md-push-4">
        <h3 className="subtitle-sez margin-top50">Avvenimenti</h3>
        <p>{text}</p>
      </div>
      <div className="col-md-4 col-sm-12 col-md-pull-8">
        <div className="owl-carousel owl-theme avvenimenti">
          { trends.map(trend => { return <React.Fragment key={trend['image']}>
            <div className="item">
              <img src={`../images/${trend['image']}`} className="img-responsive center-block" alt={trend.title} />
              { trend.title && <div id="overlay" className="overlay-text">{trend.title}</div> }
            </div>
          </React.Fragment>
          })}
        </div>
      </div>
    </div>
  )
}

export default Trends
