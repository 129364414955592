import React, { useState, useEffect } from 'react';
import axios from 'axios';
import classNames from "classnames";

import YoutubeVideo from './YoutubeVideo';

function VideosHome() {
  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState();
  const [videosSeason2, setVideosSeason2] = useState();

  const [season, setSeason] = useState("one");

  useEffect(async () => {
    const result = await axios(
      `${window.location.origin}/struttura.json`
    );

    setVideos(result.data.videos);
    setVideosSeason2(result.data.videosSeason2);
    setLoading(false);
  }, []);
  return (
    <>
      <div className="btn-row-intro d-flex justify-content-center">
        <a
          to="/decadi"
          className={`btn btn--dark ${season == "one" ? "btn--dark-selected" : ""}`}
          onClick={ e => setSeason("one")}>Stagione 1</a>
        <a
          to="/decadi"
          className={`btn btn--dark ${season == "two" ? "btn--dark-selected" : ""}`}
          onClick={ e => setSeason("two")}>Stagione 2</a>
      </div>

      <div className="container sez-video">
        { season == "one" && <div className="row">
          <div className="btn-row-intro d-flex justify-content-center">
            <h2 className="title">Stagione 1</h2>
          </div>
            <div className="row">
              { loading ? "loading your videos..." : videos.map((group, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-4">
                      <YoutubeVideo video={group[0]} />
                    </div>
                    { group[1] && <div className="col-md-4">
                      <YoutubeVideo video={group[1]} />
                    </div>}
                    { group[2] && <div className="col-md-4">
                      <YoutubeVideo video={group[2]} />
                    </div>}
                  </div>
                )
              }) }
            </div>
          </div>}
        { season == "two" && <div className="row">
          <div className="btn-row-intro d-flex justify-content-center">
            <h2 className="title">Stagione 2</h2>
          </div>
            <div className="row">
              { loading ? "loading your videos..." : videosSeason2.map((group, i) => {
                return (
                  <div className="row" key={i}>
                    <div className="col-md-4">
                      <YoutubeVideo video={group[0]} />
                    </div>
                    { group[1] && <div className="col-md-4">
                      <YoutubeVideo video={group[1]} />
                    </div>}
                    { group[2] && <div className="col-md-4">
                      <YoutubeVideo video={group[2]} />
                    </div>}
                  </div>
                )
              }) }
            </div>
          </div>}
      </div>
    </>
  )
}

export default VideosHome;

