import React from 'react';
import {
  Link
} from "react-router-dom";

import Trends from '../components/Trends'
import Games from '../components/Games'
import VideoYoutube from '../components/VideoYoutube'

function Year(year) {
  return (
    <>
      <div className="container anni" id={`container_${year.year}`} match={`${year.year}`}>
        <div className="space"></div>

        <div className="row">
          <div className="col-md-12">
            <Link to="/decadi" className="btn-decadi">◀&nbsp;&nbsp;Torna alle decadi</Link>
            <p className="title-anni">{year.year}</p>
            <h3 className="subtitle">{year.title}</h3>
          </div>
        </div>

        { year.videos && year.videos[0] && 
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="col">
                <h3 className="subtitle-sez margin-top50">Video Intervista</h3>
                <p className="autore">{year.videos[0].author}</p>
                <p className="spec">{year.videos[0].source}</p>
              </div>
            </div>

            <div className="col-md-6">
              {/* button trigger modal */}
              <a href="#" data-toggle="modal" data-target={`#video_${year.videos[0].id}`}><img src={`../images/giornalisti/${year.videos[0].author.split(" ").pop().toLowerCase()}.jpg`} /></a>
              {/* Modal */}
              <div className="modal fade" id={`video_${year.videos[0].id}`} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                      <iframe width="100%" height="400" src={`https://www.youtube.com/embed/${year.videos[0].id}`} className="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </>
        }

        { year.videos && year.videos[1] && 
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="col col__rx">
                  <h3 className="subtitle-sez margin-top50">Video intervista</h3>
                  <p className="autore">{year.videos[1].author}</p>
                  <p className="spec">{year.videos[1].source}</p>
                </div>
              </div>

              <div className="col-md-6">
                {/* button trigger modal */}
                <a href="#" data-toggle="modal" data-target={`#video_${year.videos[1].id}`}><img src={`../images/giornalisti/${year.videos[1].author.split(" ").pop().toLowerCase()}.jpg`} /></a>
                {/* Modal */}
                <div className="modal fade" id={`video_${year.videos[1].id}`} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      </div>
                      <div className="modal-body">
                        <iframe width="100%" height="400" src={`https://www.youtube.com/embed/${year.videos[1].id}`} className="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }


        <Trends text={year.trends_text} trends={year.trends} />

        <div className="container-fluid white">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h3 className="subtitle-sez margin-top50">Avanzamenti tecnologici CPU</h3>
                <div className="col-md-6">
                  <ul>
                    <li>{year.tech_mhz}</li>
                    <li>{year.tech_best_feature}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>{year.tech_productive_process}</li>
                    <li>{year.tech_socket}</li>
                  </ul>
                </div>
                <br />
                <br />
                <br />
                <p>{year.tech_text}</p>
              </div>
              <div className="col-md-4">
                <img src={`../images/${year.tech_cpu_image}`} className="img-responsive center-block margin-top50" alt="" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="subtitle-sez margin-top50">Scena overclocking</h3>
                <p>{year.modding_text}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center">
          <div className="col-md-12">
            <h3 className="subtitle">TOP 10</h3>
            <p className="title-anni">{year.year}</p>
          </div>
        </div>
        <Games games={year.games} />
      </div>
    </>
  )
}

export default Year
