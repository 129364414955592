import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";

import Footer from '../components/Footer';
import VideosHome from '../components/VideosHome';

function Home({ struttura }) {
  const images = {
    0: 'images/slide-1980.jpg',
    1: 'images/slide-1990.jpg',
    2: 'images/slide-2000.jpg',
    3: 'images/slide-2010.jpg',
    4: 'images/slide-2020.jpg',
  }
  const images_count = Object.keys(images).length
  const [imageIndex, setImageIndex] = useState(0);
  const [imgSrc, setImgSrc] = useState(images[imageIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex(imageIndex + 1 >= images_count ? 0 : imageIndex + 1);
      setImgSrc(images[imageIndex + 1 >= images_count ? 0 : imageIndex + 1])
    }, 4000);
    return () => clearInterval(interval);
  }, [imageIndex]);

  return (
    <>
      <div id="home" className="intro-home">
        <div className="claim-home">
          <img src="images/logo-pc-gaming.png" className="center-block img-responsive center-logo" alt="" />

          <div className="d-flex align-items-center justify-content-center" style={{margin: '25px 0'}}>
            <p className="pre-logo-intel">powered by</p><img src="images/logo-intel.png" className="logo-intel" alt="" /><div className="divider-plus"></div><img src="images/logo-vgp-footer.png" className="logo-vgp-intro" alt="" />
          </div>
          <br/>
          <div className="btn-row-intro d-flex justify-content-center">
            <Link to="/decadi" className="btn btn--dark">INIZIA IL TUO VIAGGIO</Link>
            <a href="#video_interviste" className="btn btn--light">VIDEO INTERVISTE
              <svg xmlns="http://www.w3.org/2000/svg" width="13.7" height="17.6" viewBox="0 0 13.7 17.6" className="arrow-btn">
                <g id="Raggruppa_17" data-name="Raggruppa 17" transform="translate(-15.6 10.3)">
                <g id="Raggruppa_5" data-name="Raggruppa 5" transform="translate(1 -21)">
                <g id="Raggruppa_2" data-name="Raggruppa 2" transform="translate(15.6 11.7)">
                <path id="Tracciato_2" data-name="Tracciato 2" d="M0,0,11.7,7.8,0,15.6V0Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                </g>
                </g>
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div className="cover-video">
          <img src={imgSrc} className="img-responsive" id="background-home" alt="" />
        </div>
        <div className="layer"></div>
      </div>

      <div className="container margin-tb50" id="video_interviste">
        <div className="row text-center">
          <div className="col-md-12">
            <h2 className="title">Un’incredibile viaggio nel tempo!</h2>
            <p>Immergiti nelle video interviste degli esperti che abbiamo selezionato per te!<br />Ascoltando i loro racconti verrai catapultato indietro nel tempo fino alle <b>origini del PC Gaming.</b></p>
          </div>
        </div>
      </div>

      <VideosHome />

      <Footer />
    </>
  );
}

export default Home;
