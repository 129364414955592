import React, { useState } from 'react';
import './trends.css'

function YoutubeVideo({ video }) {
  const video_src = `https://www.youtube.com/embed/${video.id}`;

  const [src, setSrc] = useState('');

  const showModal = () => {
    setSrc(video_src);
  }

  const hideModal = () => {
    setSrc("");
  }

  return (
    <>
      {/* button trigger modal */}
      <a href="#" data-toggle="modal" data-target={`#video_${video.id}`} onClick={showModal}><img src={`../images/giornalisti/${video.author.split(" ").pop().toLowerCase()}.jpg`} /></a>
      <div className="modal fade" id={`video_${video.id}`} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" onClick={hideModal}>
        <div className="modal-dialog modal-lg" role="document" onClick={e => e.stopPropagation()}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header" onClick={e => e.stopPropagation()}>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body" onClick={e => e.stopPropagation()}>
              <iframe width="100%" height="400" src={src} className="video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>

      <div>
      {video.year &&
        <p className="anno">{video.year}</p>
        }
        <p className="autore">{video.author}</p>
        <p className="spec">{video.source}</p>
      </div>
    </>
  )
}

export default YoutubeVideo
